import React, { useState, useEffect,useRef } from 'react';
import { Router, Route, Switch,useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import Landing from "./Landing.js"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Upload from "./views/Upload";
import Lists from "./views/Lists";
import Affiliate from "./views/Affiliate"
import Admin from "./views/Admin";
import Consultation from "./views/Consultation";
import Terms from "./views/Terms";
import Privacy from "./views/Privacy";
import ColdCallers from "./views/ColdCallers";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

import Application from "./Application";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();
const host = window.location.hostname;
const root = host === 'localhost' ? 'http://localhost:80' : 'https://api2.ninjaskiptracing.com';
const App = () => {
  useEffect(() => {
    // Function to parse query parameters
    const getQueryParams = (query) => {
      const params = new URLSearchParams(query);
      const result = {};
      for (let param of params.entries()) {
        result[param[0]] = param[1];
      }
      return result;
    };

    // Parse query parameters from the window.location.search
    const queryParams = getQueryParams(window.location.search);
    const message = queryParams.msg;

    if (message) {
      // Delay the notification by 1 second (1000 milliseconds)
      const timeoutId = setTimeout(() => {
        toast.success(message, { autoClose: 3000, theme: "colored" });

        // Remove the 'msg' parameter from the URL
        const params = new URLSearchParams(window.location.search);
        params.delete('msg');
        const newUrl = window.location.pathname + '?' + params.toString();
        window.history.replaceState({}, '', newUrl);
      }, 1000);

      // Clear the timeout if the component unmounts before the delay finishes
      return () => clearTimeout(timeoutId);
    }
    checkAnnouncement();
    checkAnnouncement2();
  }, []);
  async function checkAnnouncement(){
    try{
    const announcementResponse = await fetch(`${root}/v1/announcement/`, {
      method: 'GET',
    });
    console.log(announcementResponse);

    var responseText = await announcementResponse.text()
    if (responseText != null && responseText.length > 0 && announcementResponse.ok) {
      let notificationType = "default";
    
      if (responseText.includes("[Info]")) {
        notificationType = "info";
      } else if (responseText.includes("[Success]")) {
        notificationType = "success";
      } else if (responseText.includes("[Warn]")) {
        notificationType = "warn";
      } else if (responseText.includes("[Error]")) {
        notificationType = "error";
      }
    
      const notificationText = responseText
        .replace("[Info]", "")
        .replace("[Success]", "")
        .replace("[Warn]", "")
        .replace("[Error]", "")
        .trim();
    
      const toastOptions = { autoClose: false, theme: "colored",draggable:true };
    
      switch (notificationType) {
        case "info":
          toast.info(notificationText, toastOptions);
          break;
        case "success":
          toast.success(notificationText, toastOptions);
          break;
        case "warn":
          toast.warn(notificationText, toastOptions);
          break;
        case "error":
          toast.error(notificationText, toastOptions);
          break;
        default:
          toast(notificationText, toastOptions);
          break;
      }
    }
    
  }
  catch(err){
    console.log(59,err);
  }
  }
  async function checkAnnouncement2(){
    try{
    const announcementResponse = await fetch(`https://ninjaskiptracing.com/announcement.txt`, {
      method: 'GET',
    });
    console.log(announcementResponse);

    var responseText = await announcementResponse.text()

    if (responseText != null && responseText.length > 0 && announcementResponse.ok&&responseText.length<2000) {
      let notificationType = "default";
    
      if (responseText.includes("[Info]")) {
        notificationType = "info";
      } else if (responseText.includes("[Success]")) {
        notificationType = "success";
      } else if (responseText.includes("[Warn]")) {
        notificationType = "warn";
      } else if (responseText.includes("[Error]")) {
        notificationType = "error";
      }
    
      const notificationText = responseText
        .replace("[Info]", "")
        .replace("[Success]", "")
        .replace("[Warn]", "")
        .replace("[Error]", "")
        .trim();
    
      const toastOptions = { autoClose: false, theme: "colored",draggable:true };
    
      switch (notificationType) {
        case "info":
          toast.info(notificationText, toastOptions);
          break;
        case "success":
          toast.success(notificationText, toastOptions);
          break;
        case "warn":
          toast.warn(notificationText, toastOptions);
          break;
        case "error":
          toast.error(notificationText, toastOptions);
          break;
        default:
          toast(notificationText, toastOptions);
          break;
      }
    }
    
  }
  catch(err){
    console.log(59,err);
  }
  }

  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
      <ToastContainer />
      <NavBar />
          <Switch>
            <Route path="/" exact component={Landing} />
            <Route path="/profile" component={Profile} />
            <Route path="/external-api" component={ExternalApi} />
            <Route path="/upload" component={Upload} />
            <Route path="/lists" component={Lists} />
            <Route path="/affiliate" component={Affiliate} />
            <Route path="/admin" component={Admin} />
            <Route path="/unlimited" component={Consultation} />
            <Route path="/terms" component={Terms} />
            <Route path="/privacy" component={Privacy} />
          </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
